import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 816.000000 568.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,568.000000) scale(0.100000,-0.100000)">

<path d="M1482 3324 c-12 -8 -22 -24 -22 -34 0 -11 27 -64 59 -117 l60 -98 -9
-85 c-9 -87 -40 -512 -42 -585 -1 -36 4 -46 63 -107 l65 -67 347 0 c460 -1
546 14 627 105 64 73 66 88 59 344 -1 25 6 78 16 119 20 89 12 166 -33 303
-36 108 -64 146 -139 186 -85 45 -168 52 -628 52 -346 0 -404 -2 -423 -16z
m965 -60 c67 -19 125 -62 149 -110 14 -30 19 -61 19 -134 0 -84 -3 -102 -28
-151 l-27 -56 21 -48 c18 -41 21 -61 16 -121 -8 -110 -46 -166 -147 -213 -53
-25 -62 -26 -293 -29 l-239 -4 7 89 c4 48 9 90 12 93 2 3 88 6 191 7 165 1
188 4 199 19 16 22 17 86 1 102 -8 8 -67 12 -201 12 l-189 0 7 88 c4 48 9 89
12 92 3 3 86 6 186 8 170 2 183 4 200 23 24 29 24 141 0 160 -13 11 -75 14
-292 16 -152 1 -308 2 -348 2 l-72 1 -55 84 c-31 47 -56 88 -56 91 0 4 197 4
438 0 334 -5 449 -10 489 -21z m-570 -238 c3 -2 0 -89 -6 -193 -7 -103 -14
-243 -18 -310 l-6 -123 -134 0 -134 0 5 43 c3 23 8 87 11 142 7 117 23 328 31
400 l5 50 120 -2 c67 -1 123 -4 126 -7z"/>
<path d="M3097 3306 c-81 -29 -126 -65 -162 -129 -33 -59 -44 -163 -45 -428 0
-162 2 -181 25 -250 48 -140 75 -178 154 -219 57 -29 135 -39 346 -46 278 -10
434 22 504 102 63 71 86 244 76 569 -9 263 -23 305 -128 371 -73 47 -129 54
-427 54 -264 0 -278 -1 -343 -24z m443 -111 c0 -83 0 -85 -26 -91 -15 -4 -68
-4 -119 -1 -108 7 -163 0 -174 -22 -5 -9 -12 -110 -16 -226 -7 -224 -2 -265
34 -265 30 0 34 -15 26 -106 l-7 -84 -38 0 c-56 0 -167 37 -201 68 -77 68 -85
114 -71 428 8 201 10 214 34 255 35 60 57 77 130 105 57 21 79 23 246 23 l182
1 0 -85z m233 64 c70 -23 102 -47 138 -106 24 -38 24 -41 24 -303 0 -240 -2
-269 -19 -303 -28 -56 -69 -93 -134 -118 -51 -21 -77 -24 -249 -27 l-193 -4 0
90 c0 76 3 91 18 97 9 3 71 6 137 5 156 -2 151 -3 169 16 14 13 16 50 16 245
l0 230 -30 17 c-29 17 -30 18 -30 100 l0 82 45 0 c24 0 73 -9 108 -21z m-152
-479 c-2 -74 -4 -136 -5 -137 0 -1 -75 -2 -166 -4 l-165 -2 3 133 c1 93 6 136
15 142 6 4 82 7 167 5 l155 -2 -4 -135z"/>
<path d="M4440 3316 c-75 -22 -98 -34 -144 -76 -62 -57 -75 -103 -83 -285 -6
-161 6 -237 47 -287 18 -22 17 -23 -10 -39 -27 -17 -28 -20 -35 -141 -4 -68
-5 -148 -3 -178 5 -81 0 -80 344 -80 552 0 613 19 715 222 80 159 67 308 -36
411 -21 21 -41 37 -46 37 -19 0 -8 19 14 22 17 2 32 23 63 88 41 84 41 86 37
180 -3 52 -9 105 -15 118 l-11 22 -396 -1 c-291 0 -408 -4 -441 -13z m805 -68
c4 -18 5 -58 3 -88 l-3 -55 -344 -3 c-234 -1 -349 -6 -358 -13 -17 -15 -18
-139 0 -163 10 -14 30 -18 97 -19 l85 -2 3 -94 3 -94 -118 5 c-250 11 -338 87
-338 293 0 141 40 203 159 246 46 17 87 18 428 19 l377 0 6 -32z m-237 -348
c81 -11 128 -29 183 -72 92 -71 103 -259 19 -346 -15 -16 -54 -40 -87 -53 -57
-24 -65 -24 -451 -27 l-393 -3 3 93 3 93 350 3 c230 2 353 7 362 14 18 15 16
83 -3 102 -12 12 -36 16 -100 16 l-84 0 0 43 c0 23 -3 66 -6 95 l-7 52 69 0
c38 0 102 -5 142 -10z"/>
<path d="M5402 3318 c-7 -7 -12 -19 -12 -27 0 -9 -14 -48 -31 -88 l-32 -71 70
-97 70 -96 8 -157 c4 -86 10 -239 14 -340 5 -146 9 -185 21 -192 9 -6 207 -10
482 -10 l467 0 71 63 71 62 -6 115 c-7 124 -20 160 -57 160 -20 0 -20 2 10 33
l32 33 -10 109 c-11 124 -23 145 -87 145 l-35 0 51 63 50 63 -10 104 c-7 71
-15 110 -26 122 -15 17 -50 18 -558 18 -407 0 -544 -3 -553 -12z m1082 -118
c4 -44 5 -84 1 -90 -9 -14 -866 -14 -897 1 -20 9 -128 147 -128 163 0 3 229 6
509 6 l508 0 7 -80z m-641 -242 c4 -40 10 -139 13 -220 l7 -148 333 0 333 0 6
-57 c3 -32 8 -75 10 -95 l5 -38 -464 0 -464 0 -6 78 c-9 108 -26 426 -26 495
l0 57 124 0 123 0 6 -72z m662 -61 c2 -7 6 -49 9 -94 l5 -83 -192 0 -192 0
-45 42 c-111 102 -139 128 -145 137 -4 7 89 10 275 10 211 0 282 -3 285 -12z"/>
<path d="M1487 1818 c-8 -5 -7 -11 5 -20 14 -11 17 -38 21 -165 4 -139 3 -155
-14 -174 -11 -12 -19 -26 -19 -31 0 -12 198 -10 242 2 100 28 154 131 128 244
-18 83 -63 126 -147 142 -48 9 -203 10 -216 2z m217 -58 c31 -37 39 -79 34
-170 -3 -63 -7 -74 -36 -103 -26 -26 -35 -30 -50 -21 -15 10 -17 32 -20 168
l-3 156 25 0 c15 0 34 -12 50 -30z"/>
<path d="M2250 1810 c0 -5 -16 -44 -35 -86 -19 -41 -35 -77 -35 -80 0 -19 -85
-174 -96 -174 -8 0 -14 -10 -14 -25 0 -23 3 -25 50 -25 41 0 50 3 50 18 0 10
-5 23 -12 30 -9 9 -9 18 0 37 11 23 17 25 76 25 l64 0 12 -35 c15 -47 47 -75
83 -75 68 0 122 65 60 72 -25 3 -33 16 -96 166 -55 128 -73 162 -88 162 -10 0
-19 -4 -19 -10z m16 -227 c-8 -9 -67 -9 -72 -1 -3 4 5 27 17 50 l22 43 19 -44
c10 -24 16 -46 14 -48z"/>
<path d="M2640 1776 c0 -24 6 -48 13 -55 17 -13 27 -5 27 24 0 26 18 45 42 45
17 0 18 -12 18 -154 0 -127 -3 -155 -15 -160 -12 -4 -13 -10 -3 -31 11 -24 15
-25 95 -25 83 0 97 6 68 30 -12 10 -15 43 -15 177 l0 164 23 -3 c14 -2 23 -11
25 -25 3 -21 36 -53 54 -53 5 0 8 22 8 49 0 35 -4 51 -16 55 -9 3 -85 6 -170
6 l-154 0 0 -44z"/>
<path d="M3940 1809 c0 -6 7 -12 15 -15 11 -5 15 -22 15 -65 0 -32 -4 -59 -8
-59 -5 0 -26 24 -46 54 -50 73 -73 86 -140 85 -64 -2 -93 -22 -63 -44 15 -10
17 -31 17 -142 0 -124 -1 -131 -22 -145 l-23 -15 32 -17 c35 -17 133 -22 133
-6 0 6 -9 10 -20 10 -19 0 -20 7 -20 100 0 55 2 100 4 100 2 0 36 -43 76 -95
59 -77 121 -135 146 -135 2 0 4 37 4 83 0 45 3 126 7 179 5 87 8 97 27 102 36
9 4 23 -66 29 -38 2 -68 1 -68 -4z"/>
<path d="M3252 1763 c-114 -259 -142 -315 -142 -288 0 5 -5 4 -12 -3 -21 -21
19 -52 69 -52 44 0 55 14 32 37 -9 9 -9 20 1 42 12 29 16 31 66 31 48 0 56 -3
63 -23 5 -13 26 -38 46 -56 31 -27 43 -32 69 -27 30 6 66 38 66 58 0 5 -8 7
-19 3 -10 -3 -23 0 -29 7 -5 7 -36 76 -67 153 -31 77 -59 146 -62 153 -3 6
-17 12 -32 12 -23 0 -31 -8 -49 -47z m28 -159 c0 -13 -2 -24 -4 -24 -2 0 -11
-3 -20 -6 -19 -7 -22 17 -5 47 13 25 29 16 29 -17z"/>
<path d="M4386 1761 c-36 -72 -40 -98 -16 -106 11 -4 18 -11 15 -16 -4 -5 -14
-5 -25 1 -13 7 -21 7 -25 -1 -8 -12 -8 -11 20 -28 18 -11 17 -12 -8 -6 -24 5
-29 1 -46 -37 -11 -24 -22 -51 -25 -61 -4 -11 -15 -21 -26 -24 -11 -3 -20 -9
-20 -14 0 -15 70 -39 111 -39 42 0 48 6 24 24 -13 9 -13 16 -3 43 7 18 19 34
28 36 31 6 62 -6 70 -28 11 -28 90 -75 127 -75 34 0 46 8 62 38 11 21 10 22
-13 16 -19 -4 -26 -1 -31 18 -15 48 -108 283 -116 292 -4 5 -24 10 -44 11 -34
2 -38 -1 -59 -44z"/>
<path d="M4757 1803 c-4 -3 -7 -21 -7 -38 0 -30 4 -33 45 -45 l45 -13 0 -103
c0 -81 -3 -106 -16 -119 -19 -19 -20 -18 30 -39 36 -15 176 -23 176 -10 0 3
-7 12 -16 21 -8 9 -13 22 -10 30 3 8 6 64 6 125 l0 111 49 -7 c45 -6 49 -5 55
16 15 58 10 65 -55 72 -81 8 -294 8 -302 -1z"/>
<path d="M5250 1799 c0 -4 5 -11 10 -14 13 -8 1 -285 -13 -303 -12 -15 -13
-14 43 -35 74 -28 205 -20 159 9 -9 5 -10 50 -6 158 5 127 8 152 23 163 10 7
16 15 13 17 -9 8 -229 13 -229 5z"/>
<path d="M5770 1791 c11 -8 5 -9 -22 -5 -120 20 -187 -178 -93 -276 40 -42
163 -84 227 -78 44 5 57 11 89 45 76 82 71 238 -9 298 -21 16 -46 21 -117 23
-63 2 -86 0 -75 -7z m65 -165 c-4 -57 -12 -89 -22 -98 -14 -11 -15 -2 -11 82
4 99 12 130 30 112 7 -7 8 -42 3 -96z"/>
<path d="M6240 1792 c-78 -3 -103 -13 -82 -34 7 -7 12 -22 12 -35 0 -13 6 -26
14 -29 8 -3 23 -18 32 -32 17 -27 17 -27 -3 -9 -30 26 -40 6 -45 -86 -2 -54
-8 -81 -18 -87 -8 -5 -12 -11 -10 -13 13 -11 105 -27 152 -26 51 0 52 1 31 16
-16 11 -23 26 -23 49 0 48 14 49 45 4 28 -41 35 -44 98 -56 20 -3 37 -10 37
-15 0 -5 7 -9 15 -9 12 0 15 13 15 58 0 33 3 107 7 167 5 93 9 108 25 113 35
9 18 19 -43 26 -100 10 -145 8 -131 -6 19 -19 14 -33 -5 -16 -25 21 -39 23
-123 20z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
